import React from 'react'
import ContactSection from '../components/Contact'
import Footer from '../components/Footer';


const ContactPage = () => {
 

    return (
        <>
       <ContactSection />
       <Footer />
        </>
    )
}

export default ContactPage
